import { unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1d076904"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "BatchRechargeForm"
};
const _hoisted_2 = {
  style: {
    "margin-top": "10px"
  }
};
import InputText from "@/components/Form/InputText";
import VantMsgLib from "@/utils/Vant/VantMsgLib";
import Apis_User from "@/apis/Apis_User";
import SelfMoney from "@/components/Info/SelfMoney";
import HeaderNavBar from "@/components/Header/HeaderNavBar";
import { ref } from "vue";
export default {
  __name: 'BatchRecharge',
  setup(__props) {
    const Options = {
      CacheDataField: 'UserList-BatchRecharge',
      //缓存数据字段
      Name: '批量充值' //[功能名字]用于提示用户是否执行该功能
    };

    const refsSelfMoney = ref();
    const onSubmit = options => {
      let {
        arr
      } = options;
      if (!Array.isArray(arr)) {
        VantMsgLib.alertError('充值格式不正确');
        return;
      }
      let str = arr.toString();
      Apis_User.batchUserMoney(str).then(res => {
        if (!Apis_User.checkResCode(res)) {
          return;
        }
        refsSelfMoney.value.GetSelfMoney();
      });
    };
    return (_ctx, _cache) => {
      const _component_van_tag = _resolveComponent("van-tag");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_unref(HeaderNavBar), {
        title: "批量充值"
      }), _createElementVNode("div", _hoisted_1, [_createVNode(_component_van_tag, {
        plain: "",
        type: "danger",
        style: {
          "margin-bottom": "10px"
        }
      }, {
        default: _withCtx(() => [_createTextVNode(" 格式:用户名----充值次数(整数) ")]),
        _: 1
      }), _createVNode(_unref(InputText), {
        Options: Options,
        onOnSubmit: onSubmit
      }), _createElementVNode("div", _hoisted_2, [_createVNode(_unref(SelfMoney), {
        ref_key: "refsSelfMoney",
        ref: refsSelfMoney
      }, null, 512)])])], 64);
    };
  }
};