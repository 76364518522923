import { toDisplayString as _toDisplayString, unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "SelfMoneyView"
};
import { onMounted, ref } from "vue";
import Apis_Agent from "@/apis/Apis_Agent";
import LangCom from "@/utils/Lang/LangCom";
export default {
  __name: 'SelfMoney',
  setup(__props, {
    expose
  }) {
    const LangLib = new LangCom('Info', 'SelfMoney');
    const GetLangTemplate = field => LangLib.GetTemplateLang(field);
    let money = ref({
      rec: '...',
      reg: '...'
    });
    const GetSelfMoney = () => {
      let call = () => {
        money.value = {
          rec: '...',
          reg: '...'
        };
        Apis_Agent.getSelfMoney().then(res => {
          console.log('money', res);
          if (!Apis_Agent.checkResCode(res)) {
            return;
          }
          money.value = {
            rec: res.money_rec || -2,
            reg: res.money_reg || -2
          };
        });
      };
      //延迟更新,避免请求冲突
      setTimeout(() => call(), 1000);
    };
    onMounted(() => {
      GetSelfMoney();
    });
    expose({
      GetSelfMoney
    });
    return (_ctx, _cache) => {
      const _component_van_tag = _resolveComponent("van-tag");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_tag, {
        color: "#7232dd",
        style: {
          "margin-right": "10px"
        }
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(GetLangTemplate('tag_reg')) + ":" + _toDisplayString(_unref(money).reg), 1)]),
        _: 1
      }), _createVNode(_component_van_tag, {
        type: "primary"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(GetLangTemplate('tag_rec')) + _toDisplayString(_unref(money).rec), 1)]),
        _: 1
      })]);
    };
  }
};